import React, { memo, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { appURLs } from "@/app/app-urls.mjs";
import type { Rarity } from "@/game-fortnite/components/ItemBox";
import { ItemBox } from "@/game-fortnite/components/ItemBox";
import type { PlayerMatches } from "@/game-fortnite/models/matchlist.mjs";
import type { PlayerWeaponStat } from "@/game-fortnite/models/player-weapon-stats.mjs";
import useWeaponsFromItems from "@/game-fortnite/utils/use-weapons-from-items.mjs";
import SharedMatchHistoryHeader from "@/shared/MatchHistoryHeader.jsx";
import { calcRate } from "@/util/helpers.mjs";
import { formatOrdinalNumber, getLocale } from "@/util/i18n-helper.mjs";

function MatchHistoryHeader({ matches }: { matches: PlayerMatches }) {
  const { t } = useTranslation();
  const weapons = useWeaponsFromItems();

  const { stats, iconList } = useMemo(() => {
    const stats = {
      avgPlacement: 0,
      wins: 0,
      top10: 0,
      top25: 0,
      kills: 0,
      hsPercent: 0,
      accuracy: 0,
      matches: 0,
    };
    const weaponStatsMap: Record<
      string,
      { kills: number; weapon: PlayerWeaponStat["weapon"] }
    > = {};

    let placementSum = 0;
    for (let i = 0; i < 20 && i < matches.length; i++) {
      const match = matches[i];
      placementSum += match.placement;
      stats.wins += match.placement === 1 ? 1 : 0;
      stats.top10 += match.placement <= 10 ? 1 : 0;
      stats.top25 += match.placement <= 25 ? 1 : 0;
      stats.kills += match.kills;
      stats.hsPercent += match.headshotPercentage;
      stats.accuracy += match.accuracy;
      stats.matches += 1;
      for (const weaponStats of match.weaponStats) {
        if (!weaponStatsMap[weaponStats.weapon.key])
          weaponStatsMap[weaponStats.weapon.key] = {
            kills: 0,
            weapon: weaponStats.weapon,
          };
        weaponStatsMap[weaponStats.weapon.key].kills += weaponStats.kills;
      }
    }

    stats.avgPlacement = calcRate(placementSum, stats.matches, 0);

    const iconList = Object.entries(weaponStatsMap)
      .sort(([, { kills: kills1 }], [, { kills: kills2 }]) => kills2 - kills1)
      .slice(0, 3)
      .map(([, stats]) => {
        const weapon = weapons[stats.weapon.key.toLowerCase()];
        if (!weapon) return null;
        return {
          Icon: stats.weapon.rarity ? (
            <ItemBox
              id={weapon.id}
              name={weapon.displayName}
              rarity={stats.weapon.rarity.toUpperCase() as Rarity}
              width={36}
              src={`${appURLs.CDN}/fortnite/images/${weapon.icon}.webp`}
            />
          ) : null,
          stat: t("fortnite:stats.countElims", "{{count, number}} Elims", {
            count: stats.kills,
          }),
        };
      })
      .filter((i) => !!i);

    return { stats, iconList };
  }, [matches, t, weapons]);

  const statLines = useMemo(() => {
    return [
      {
        stat: formatOrdinalNumber(getLocale(), stats.avgPlacement),
        description: t("fortnite:stats.avgPlacement", "Avg. Placement", {
          matches: stats.matches,
        }),
      },
      {
        stat: stats.top10,
        description: t("fortnite:stats.top10", "Top 10"),
      },
      {
        stat: stats.top25,
        description: t("fortnite:stats.top25", "Top 25"),
      },
      {
        stat: calcRate(stats.kills, stats.matches).toLocaleString(getLocale(), {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        }),
        description: t("fortnite:stats.elimsPerMatchShort", "E/Match"),
      },
      {
        stat: stats.kills,
        description: t("fortnite:stats.elims", "Elims"),
      },
      {
        stat: calcRate(stats.hsPercent, stats.matches).toLocaleString(
          getLocale(),
          {
            maximumFractionDigits: 1,
            minimumFractionDigits: 1,
          },
        ),
        description: t("common:stats.hsPercent", "HS%"),
      },
      {
        stat: calcRate(stats.accuracy, stats.matches).toLocaleString(
          getLocale(),
          {
            maximumFractionDigits: 1,
            minimumFractionDigits: 1,
          },
        ),
        description: t("common:stats.accuracyShort", "Acc."),
      },
    ];
  }, [stats, t]);

  return (
    <SharedMatchHistoryHeader
      statColumns={statLines}
      IconList={iconList}
      padding="var(--sp-5)"
    />
  );
}

export default memo(MatchHistoryHeader);
