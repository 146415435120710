import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { readState } from "@/__main__/app-state.mjs";
import { appURLs } from "@/app/app-urls.mjs";
import { formatDuration } from "@/app/util.mjs";
import type { Rarity } from "@/game-fortnite/components/ItemBox.jsx";
import { ItemBox } from "@/game-fortnite/components/ItemBox.jsx";
import {
  FORTNITE_PLATFORMS,
  FORTNITE_PLATFORMS_SYMBOLS,
} from "@/game-fortnite/constants/platforms.mjs";
import { FORTNITE_PLAYLISTS } from "@/game-fortnite/constants/playlists.mjs";
import { FORTNITE_QUEUES } from "@/game-fortnite/constants/queues.mjs";
import type { MatchQuery } from "@/game-fortnite/models/match-query.mjs";
import getHistogram from "@/game-fortnite/utils/get-histogram.mjs";
import Static from "@/game-fortnite/utils/static.mjs";
import useWeaponsFromItems from "@/game-fortnite/utils/use-weapons-from-items.mjs";
import getDataTablePills from "@/shared/get-data-table-pills.mjs";
import MatchTileExpand from "@/shared/MatchTileExpand.jsx";
import globals from "@/util/global-whitelist.mjs";
import { sanitizeNumber, toFixedNumber } from "@/util/helpers.mjs";
import { getLocaleString } from "@/util/i18n-helper.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

enum MatchTileTabsEnum {
  Scoreboard = "scoreboard",
  Statistics = "statistics",
  Hits = "weaponKills",
  Weapons = "weaponHits",
}
const Tabs = {
  [MatchTileTabsEnum.Scoreboard]: () =>
    import("@/game-fortnite/components/MatchTileScoreboardMini.jsx"),
  [MatchTileTabsEnum.Statistics]: () =>
    import("@/game-fortnite/components/StatsBreakdown.jsx"),
  [MatchTileTabsEnum.Weapons]: () =>
    import("@/game-fortnite/components/MatchWeaponsTable"),
  [MatchTileTabsEnum.Hits]: () =>
    import("@/game-fortnite/components/HitStatsPostmatch.jsx"),
};
const PROFILE_MATCHTILE_TABS = [
  {
    name: ["tft:matchtabs.scoreboard", "Scoreboard"] as Translation,
    key: MatchTileTabsEnum.Scoreboard,
  },
  {
    name: ["common:navigation.statistics", "Statistics"] as Translation,
    key: MatchTileTabsEnum.Statistics,
  },
  {
    name: ["eft:navigation.weaponKills", "Weapon Kills"] as Translation,
    shortName: ["common:stats.kills", "Kills"] as Translation,
    key: MatchTileTabsEnum.Weapons,
  },
  {
    name: ["val:stats.hits", "Hits"] as Translation,
    key: MatchTileTabsEnum.Hits,
  },
];
function MatchTileContent({
  gameId,
  profileId,
}: {
  gameId: string;
  profileId: string;
}) {
  const { t } = useTranslation();
  const {
    i18n: { language },
  } = useTranslation();
  const { fortnite } = useSnapshot(readState);
  const weapons = useWeaponsFromItems();
  const matches = fortnite.matches as {
    [gameId: string]: MatchQuery;
  };

  const histogram = getHistogram(matches, profileId);
  const match = matches[gameId] as MatchQuery;
  const player = match.playerMatches.find(
    (i) => i.player.accountId === profileId,
  );
  const isWin = player.placement <= 15;
  const kills = player.kills;
  const damage = player.damageDealt;
  const accuracy = player.accuracy;
  const timeAlive = t("common:minutes", "{{minutes}} mins", {
    minutes: formatDuration(player.aliveTime * 1000, "m:ss"),
  });
  const mode = Object.getOwnPropertySymbols(FORTNITE_PLAYLISTS).find(
    (s) =>
      FORTNITE_PLAYLISTS[s].isRanked === match.isRanked &&
      FORTNITE_PLAYLISTS[s].isZeroBuild === match.isZeroBuild &&
      match.mode.startsWith("RELOAD") === FORTNITE_PLAYLISTS[s].isReload,
  );
  const queue = Object.getOwnPropertySymbols(FORTNITE_QUEUES).find(
    (s) => FORTNITE_QUEUES[s].key === match.mode,
  );

  const topWeaponStats = useMemo(() => {
    return player.weaponStats
      ?.filter((stat) => weapons[stat.weapon.key.toLowerCase()])
      ?.sort((a, b) => b.kills - a.kills)[0];
  }, [player.weaponStats, weapons]);
  const weapon = weapons[topWeaponStats?.weapon?.key?.toLowerCase() ?? ""];
  const platform = FORTNITE_PLATFORMS[FORTNITE_PLATFORMS_SYMBOLS.pc];

  return (
    <MatchTileExpand
      image={{
        src: Static.getProfileImage(gameId),
        alt: gameId,
        style: {
          scale: "1.2",
          translate: "0% 12%",
        },
        // className: Headshot(),
      }}
      isWin={isWin}
      titles={[
        {
          text: t("common:stats.placement", "{{placement, ordinal}}", {
            placement: player.placement,
          }),
          bold: true,
          color: isWin ? "var(--turq)" : "var(--red)",
        },
        FORTNITE_PLAYLISTS[mode]?.t && {
          text: FORTNITE_PLAYLISTS[mode].t,
        },
        {
          text: FORTNITE_QUEUES[queue].t,
        },
        {
          text: platform.t,
          iconLeft: <platform.icon />,
        },
        {
          text: formatDuration(player.aliveTime * 1000, "mm:ss"),
        },
      ].filter(Boolean)}
      tabDefault={MatchTileTabsEnum.Scoreboard}
      tabsToElement={Tabs}
      tabsLabel={PROFILE_MATCHTILE_TABS}
      tabArgs={{
        match,
        profileId,
      }}
      stats={[
        {
          top: (
            <span>
              {t("common:stats.killsCount", "{{count, number}} Kills", {
                count: kills,
              })}
            </span>
          ),
          pill: getDataTablePills(
            kills,
            [kills].concat(histogram.killsHist ?? []),
          ),
          bottom: t("common:stats.kills", "Kills"),
        },
        {
          top: getLocaleString(toFixedNumber(damage, 1)),
          pill: getDataTablePills(
            damage,
            [damage].concat(histogram.damageDealtHist ?? []),
          ),
          bottom: t("common:stats.damage", "Damage"),
        },
        {
          top: t("lol:percent", "{{number}}%", {
            number: sanitizeNumber(accuracy).toLocaleString(language, {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            }),
          }),
          pill: getDataTablePills(
            accuracy,
            [accuracy].concat(histogram.accuracyHist ?? []),
          ),
          bottom: t("common:accuracy", "Accuracy"),
        },
        {
          top: timeAlive,
          pill: getDataTablePills(
            player.aliveTime,
            [player.aliveTime].concat(histogram.aliveTimeHist ?? []),
          ),
          bottom: t("fortnite:stats.survivalTime", "Survival Time"),
        },
      ]}
      urlWeb={`${globals.location.origin}/fortnite/match/${profileId}/${match.seasonId}/${gameId}`}
      urlCanonical={`/fortnite/match/${profileId}/${match.seasonId}/${gameId}`}
      aside={
        topWeaponStats?.weapon?.rarity && weapon ? (
          <div
            style={{
              display: "grid",
              placeContent: "center",
              gap: "var(--sp-2)",
            }}
          >
            <ItemBox
              id={weapon.id}
              name={weapon.displayName}
              rarity={weapon.rarity.toUpperCase() as Rarity}
              width={40}
              src={`${appURLs.CDN}/fortnite/images/${weapon.icon}.webp`}
            />
            <div
              className="type-caption match-sub-stat"
              style={{ textAlign: "center" }}
            >
              {t("fortnite:stats.countElims", "{{count, number}} Elims", {
                count: topWeaponStats.kills,
              })}
            </div>
          </div>
        ) : null
      }
      matchDate={match.startedAt}
    />
  );
}
export default React.memo(MatchTileContent);
